import React, { useEffect, useState } from 'react'
import api from '../../api/api'
import { toast } from '../../components/ui/use-toast'
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '../../components/ui/alert-dialog'
import { Button } from '../../components/ui/button'
import { RefreshCwIcon } from 'lucide-react'
import CopyBlock from '../../components/ui/copy-block'
import OSSelect from '../../pages/OrderConfirm/OSSelect'
import SSHKeySelect from '../../pages/OrderConfirm/SSHKeySelect'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../components/ui/form'
import { useForm } from 'react-hook-form'

const ReinstallButton = ({ service, server }) => {
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [selectedOs, setSelectedOs] = useState(null)
  const [rootPassword, setRootPassword] = useState()

  const form = useForm()
  const fields = form.watch()
  const [options, setOptions] = useState({})

  useEffect(() => {
    setOptions({ ...options, ...fields })
  }, [fields])

  const handleReinstall = async () => {
    if (loading) return
    setLoading(true)

    try {
      if (!selectedOs) {
        return toast({
          title: 'Error',
          description: 'Please select an operating system',
          variant: 'destructive',
        })
      }
      const response = await api({
        method: 'POST',
        url: `/server/${service.id}/rebuild`,
        data: {
          osid: selectedOs.osid,
          sshkey: options.sshkey,
        },
      })
      if (response.result !== 'success') {
        toast({
          title: 'Error',
          description: response.message,
          variant: 'destructive',
        })
      } else {
        setRootPassword(response.password)
        toast({
          title: 'Success',
          description: 'Server is being reinstalled',
        })
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Reinstall failed',
        variant: 'destructive',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild>
        <Button
          variant="outline"
          size="sm"
          disabled={loading || !server.status || server.locked}
        >
          <RefreshCwIcon className="w-4 h-4 mr-2" />
          Reinstall
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent className="max-w-4xl w-fit">
        <AlertDialogHeader>
          <AlertDialogTitle className="mb-4">Reinstall Server</AlertDialogTitle>
          <AlertDialogDescription className="text-foreground">
            {!rootPassword && (
              <>
                <OSSelect
                  selectedOs={selectedOs}
                  setValue={(option) => setSelectedOs(option)}
                />
                <hr className="mt-10 mb-8" />
                <Form {...form}>
                  <form>
                    <FormField
                      control={form.control}
                      name="sshkey"
                      render={({ field }) => (
                        <FormItem className="mb-2">
                          <FormLabel>SSH Key</FormLabel>
                          <FormControl>
                            <SSHKeySelect {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </form>
                </Form>
              </>
            )}
            {rootPassword && (
              <div className="flex flex-col gap-2">
                <p>Root Password:</p>
                <CopyBlock value={rootPassword} />
              </div>
            )}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          {!rootPassword && (
            <Button
              onClick={handleReinstall}
              disabled={loading}
              loading={loading}
            >
              Submit
            </Button>
          )}
          <AlertDialogCancel>Close</AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default ReinstallButton
