import React, { useEffect, useState } from 'react'
import api from '../../api/api'
import { Controller } from 'react-hook-form'
import {
  DropdownMenuSeparator,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuGroup,
  DropdownMenuLabel,
  DropdownMenu,
} from '../../components/ui/dropdown-menu'
import { Button } from '../../components/ui/button'
import { ChevronDown } from 'lucide-react'
import { toast } from '../../components/ui/use-toast'
import { Badge } from '../../components/ui/badge'
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '../../components/ui/card'
import debian from '../../assets/debian.svg'
import ubuntu from '../../assets/ubuntu.svg'
import windows from '../../assets/windows.svg'

const OSLogos = {
  debian,
  ubuntu,
  windows,
}

const OSSelect = ({ selectedOs, setValue }) => {
  const [osOptions, setOsOptions] = useState([])

  useEffect(() => {
    api(
      {
        method: 'GET',
        url: '/server/ostemplates',
      },
      (data) => {
        if (data.result !== 'success') {
          return toast({
            title: 'Error',
            description: data.message,
            variant: 'destructive',
          })
        }
        setOsOptions(data.osTemplates)
      },
    )
  }, [])

  const groupedOptions = osOptions.reduce((acc, option) => {
    if (!acc[option.distro]) {
      acc[option.distro] = []
    }
    acc[option.distro].push(option)
    return acc
  }, {})

  return (
    <div className="flex flex-row gap-4">
      {Object.keys(groupedOptions)
        .filter((distro) => distro !== 'others')
        .map((distro) => (
          <Card
            className={
              'w-64' + (selectedOs?.distro === distro ? ' bg-muted' : '')
            }
            key={distro.id}
          >
            <CardHeader>
              <CardTitle>
                <img
                  src={OSLogos[distro]}
                  className="h-6 w-6 mr-2 inline-block align-top	"
                />
                {distro.charAt(0).toUpperCase() + distro.slice(1)}
              </CardTitle>
            </CardHeader>
            <CardContent>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline" className="w-full justify-between">
                    {selectedOs && selectedOs.distro === distro ? (
                      <OSOption option={selectedOs} />
                    ) : (
                      'Select'
                    )}
                    <ChevronDown className="ml-2 h-4 w-4 shrink-0" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-48">
                  {groupedOptions[distro].map((option) => (
                    <DropdownMenuItem
                      key={option.osid}
                      onSelect={() => setValue(option)}
                    >
                      <OSOption option={option} />
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>
            </CardContent>
          </Card>
        ))}
    </div>
  )
}

const OSOption = ({ option }) => {
  return (
    <>
      {`v${option.version || ''}`}
      {option.drivers && (
        <Badge className="ml-2 text-xs bg-[#76B900] px-1 hover:bg-[#76B900]">
          NVIDIA DRIVERS
        </Badge>
      )}
    </>
  )
}

export default OSSelect
