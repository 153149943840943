import { useState } from 'react'
import api from '../../api/api'
import { Button } from '../../components/ui/button'
import { toast } from '../../components/ui/use-toast'
import { Card, CardContent } from '../../components/ui/card'
import {
  CircleStopIcon,
  ListRestartIcon,
  MonitorIcon,
  PlayIcon,
  PowerOffIcon,
} from 'lucide-react'
import ReinstallButton from './ReinstallButton.js'
import CopyBlock from '../../components/ui/copy-block'
import { Badge } from '../../components/ui/badge'

const ServerControls = ({ service, server }) => {
  return (
    <Card className="mt-4">
      <CardContent className="pt-6 grid grid-cols-1 gap-8 md:grid-cols-3 md:gap-4">
        {(service?.status === 'Active' ||
          service?.status === 'Suspended' ||
          service?.status === 'Pending') && (
          <div className="grid gap-4">
            <div className="grid grid-cols-[120px_1fr] items-center gap-2">
              <div className="font-medium">Public IP</div>
              <div>
                <CopyBlock value={service.dedicatedip} />
              </div>
            </div>
            <div className="grid grid-cols-[120px_1fr] items-center gap-2">
              <div className="font-medium">Hostname</div>
              <div>{service.domain}</div>
            </div>
          </div>
        )}
        {service?.status === 'Active' && (
          <div className="grid md:col-span-2 md:justify-items-end">
            <div className="flex items-center gap-2">
              <ControlButton
                disabled={
                  !server.status || server.status === 'running' || server.locked
                }
                path={`/server/${service.id}/start`}
              >
                <PlayIcon className="w-4 h-4 mr-2" />
                Start
              </ControlButton>
              <ControlButton
                disabled={
                  !server.status || server.status !== 'running' || server.locked
                }
                path={`/server/${service.id}/stop`}
              >
                <CircleStopIcon className="w-4 h-4 mr-2" />
                Stop
              </ControlButton>
              <ControlButton
                disabled={
                  !server.status || server.status !== 'running' || server.locked
                }
                path={`/server/${service.id}/restart`}
              >
                <ListRestartIcon className="w-4 h-4 mr-2" />
                Restart
              </ControlButton>
              <ControlButton
                disabled={
                  !server.status || server.status !== 'running' || server.locked
                }
                path={`/server/${service.id}/shutdown`}
              >
                <PowerOffIcon className="w-4 h-4 mr-2" />
                Shutdown
              </ControlButton>
            </div>
            <div className="flex items-center gap-2 mt-4">
              <WebSSHButton service={service} server={server} />
              <VncButton service={service} server={server} />
              <ReinstallButton service={service} server={server} />
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  )
}

const ControlButton = ({ disabled, path, children }) => {
  const [loading, setLoading] = useState(false)

  const handleAction = async () => {
    setLoading(true)
    try {
      const response = await api({
        method: 'POST',
        url: path,
      })
      if (response.result !== 'success') {
        toast({
          title: 'Error',
          description: response.message,
          variant: 'destructive',
        })
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Action failed',
        variant: 'destructive',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Button
      variant="outline"
      size="sm"
      className={`${disabled && 'bg-gray-300'}`}
      onClick={handleAction}
      disabled={disabled || loading}
      loading={loading}
    >
      {loading ? 'Processing...' : children}
    </Button>
  )
}

const WebSSHButton = ({ service, server }) => {
  const [loading, setLoading] = useState(false)
  const disabled =
    !server.status || server.status !== 'running' || loading || server.locked

  const handleWebSSH = async () => {
    setLoading(true)
    try {
      const response = await api({
        method: 'GET',
        url: `/server/${service.id}/webssh`,
      })
      if (response.result !== 'success') {
        toast({
          title: 'Error',
          description: response.message,
          variant: 'destructive',
        })
      } else {
        window.open('https://webssh.puregpu.com/' + response.key, '_blank')
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Action failed',
        variant: 'destructive',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Button
      variant="outline"
      size="sm"
      className={`${disabled && 'bg-gray-300'}`}
      onClick={handleWebSSH}
      disabled={disabled || loading}
      loading={loading}
    >
      <MonitorIcon className="w-4 h-4 mr-2" />
      Web SSH
      <Badge className="text-xs ml-2 bg-green-500">NEW</Badge>
    </Button>
  )
}

const VncButton = ({ service, server }) => {
  const [loading, setLoading] = useState(false)
  const disabled =
    !server.status || server.status !== 'running' || loading || server.locked

  const handleVnc = async () => {
    // Open a blank window first
    const newWindow = window.open('', '', 'width=800,height=600')

    // Check if the new window was opened successfully
    if (newWindow && newWindow.document) {
      // Add a loading spinner or text to the new window
      newWindow.document.write(
        '<!DOCTYPE html><html><head><style> html, body, .container { height:100%; } .container { display: flex; justify-content: center; align-items: center; } .lds-ripple { position: relative; width: 80px; height: 80px; } .lds-ripple div { position: absolute; border: 4px solid red; opacity: 1; border-radius: 50%; animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite; } .lds-ripple div:nth-child(2) { animation-delay: -0.5s; } @keyframes lds-ripple { 0% { top: 36px; left: 36px; width: 0; height: 0; opacity: 0; } 4.9% { top: 36px; left: 36px; width: 0; height: 0; opacity: 0; } 5% { top: 36px; left: 36px; width: 0; height: 0; opacity: 1; } 100% { top: 0px; left: 0px; width: 72px; height: 72px; opacity: 0; } } </style></head><body><div class="container"><div class="lds-ripple"><div></div><div></div></div></div></body></html>',
      )
    }

    if (loading) return
    setLoading(true)
    try {
      const response = await api({
        method: 'POST',
        url: `/server/${service.id}/vnc`,
      })
      if (response.result !== 'success') {
        toast({
          title: 'Error',
          description: response.message,
          variant: 'destructive',
        })
      } else {
        const { url } = response
        newWindow.location = url
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'VNC failed',
        variant: 'destructive',
      })
      if (newWindow) newWindow.close()
    } finally {
      setLoading(false)
    }
  }

  return (
    <Button
      variant="outline"
      size="sm"
      className={`${disabled && 'bg-gray-300'}`}
      onClick={handleVnc}
      disabled={disabled}
      loading={loading}
    >
      <MonitorIcon className="w-4 h-4 mr-2" />
      VNC
    </Button>
  )
}

export default ServerControls
